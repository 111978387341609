<script lang="ts">
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import { posthog, initPosthog } from '$lib/initPosthog';
	import {
		signInWithPopup,
		signInWithEmailAndPassword,
		createUserWithEmailAndPassword,
		getRedirectResult,
		getAdditionalUserInfo,
		sendEmailVerification
	} from 'firebase/auth';
	import type { User } from 'firebase/auth';
	import { auth, googleProvider } from '$lib/firebase';
	import { createDialog } from 'svelte-headlessui';
	import Transition from 'svelte-transition';
	import { browser } from '$app/environment';
	import { disposableEmailDomains, isDisposableEmailApiCheck } from '$lib/disposableEmailDomains';

	export let currentUser: User | undefined | false = false;

	export let link: string = '/canvas';
	export let whichModal: 'login' | 'sign-up' = 'login';
	export let loginTitleMessage: string = 'Log in to Iliad to continue.';
	export let signUpTitleMessage: string = 'Sign up for Iliad to continue.';

	let email: string = '';
	let password: string = '';
	let errorMessage: string = '';

	const dialog = createDialog({ label: 'Login or Sign Up' });

	$: {
		if (browser) {
			if ($dialog.expanded) {
				document.body.style.overflowY = 'hidden';
			} else {
				document.body.style.overflowY = 'auto';
			}
		}
	}

	export function openDialog() {
		console.log('opening dialog');
		dialog.open();
	}

	export function closeDialog() {
		console.log('closing dialog');
		dialog.close();
	}

	async function loginWithGoogle() {
		try {
			const result = await signInWithPopup(auth, googleProvider);
			const additionalUserInfo = getAdditionalUserInfo(result);
			if (additionalUserInfo) {
				const isNewUser = additionalUserInfo.isNewUser;
				if (isNewUser) {
					posthog.capture('sign_up', { method: 'google' });
				} else {
					posthog.capture('login', { method: 'google' });
				}
			}
			// navigate to canvas, keeping it so back button works
			goto(link);
		} catch (error) {
			console.log('loginWithGoogle error', error);
			errorMessage = 'Failed to log in with Google.';
		}
	}

	async function loginWithEmailPassword() {
		try {
			await signInWithEmailAndPassword(auth, email, password);
			// navigate to canvas, keeping it so back button works
			posthog.capture('login', { method: 'email' });
			goto(link);
		} catch (error) {
			console.log('loginWithEmailPassword error', error);
			errorMessage = 'Invalid email or password.';
		}
	}

	async function signUpWithEmailAndPassword() {
		try {
			const domain = email.split('@')[1];
			if (disposableEmailDomains.has(domain)) {
				errorMessage = 'Disposable email addresses are not allowed.';
				return;
			}

			if (await isDisposableEmailApiCheck(email)) {
				errorMessage = 'Disposable email addresses are not allowed.';
				return;
			}

			const userCredential = await createUserWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;

			// Send email verification
			await sendEmailVerification(user);

			errorMessage = 'Verification email sent. Please check your inbox.';

			posthog.capture('sign_up', { method: 'email' });
			goto(link);
		} catch (error) {
			if (error instanceof Error && 'code' in error) {
				const firebaseError = error as { code: string };

				switch (firebaseError.code) {
					case 'auth/email-already-in-use':
						errorMessage = 'An account with this email already exists.';
						break;
					case 'auth/invalid-email':
						errorMessage = 'Please enter a valid email address.';
						break;
					case 'auth/weak-password':
						errorMessage = 'Your password is too weak. Try a stronger one.';
						break;
					default:
						console.log('firebaseError', firebaseError.code);
						errorMessage = 'Something went wrong. Please try again.';
				}
			} else {
				errorMessage = 'An unexpected error occurred.';
			}
		}
	}

	onMount(async () => {
		initPosthog();
		console.log('login onMount');

		auth.onAuthStateChanged(async (user) => {
			try {
				const redirectResult = await getRedirectResult(auth);
				console.log('redirectResult', redirectResult);
			} catch (error) {
				console.log('redirect result error', error);
			}

			currentUser = user ?? undefined;
			if (user) {
				if (user.email) {
					posthog.identify(user.email);
				}
			}
		});
	});
</script>

<div class={`fixed w-screen h-screen inset-0 ${$dialog.expanded ? 'z-50' : 'z-[-10]'}`}>
	<Transition show={$dialog.expanded}>
		<button
			aria-label="Close login or sign up modal"
			class="w-full h-full bg-zinc-900 bg-opacity-25 cursor-default"
			on:click={dialog.close}
		></button>
		<div
			class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit h-fit p-12 flex flex-col justify-center items-center
			rounded border shadow-md z-50 font-inter text-xs
			bg-primary-50 text-zinc-900 border-primary-200
			dark:bg-zinc-900 dark:text-zinc-200 dark:border-zinc-800"
			use:dialog.modal
		>
			<div
				class="w-[16rem] h-[32rem] sm:w-[22rem] sm:h-[36rem]
					flex flex-col justify-center items-center absolute-center-on-large"
			>
				<div class="absolute top-0 right-0 p-4">
					<button
						aria-label="Close login or sign up modal"
						class="w-8 h-8 flex justify-center items-center rounded text-lg
							hover:bg-primary-100 dark:hover:bg-zinc-800"
						on:click={dialog.close}
					>
						<i class="fa-solid fa-times"></i>
					</button>
				</div>
				{#if currentUser === false || currentUser}
					<div class="w-fit h-fit text-primary-600 dark:text-primary-600">
						<i class="fa-solid fa-circle-notch animate-spin text-4xl"></i>
					</div>
				{/if}

				{#if currentUser === undefined}
					<div class="w-fit h-fit p-8 flex flex-col justify-center items-center">
						<img
							loading="lazy"
							class="w-24 h-24 rounded"
							src="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/bfb4d31d-df4e-4c8f-1717-624ca1343800/smaller"
							alt="Iliad mark"
						/>
						<div class="h-8"></div>
						<div class="text-xl text-center">
							{whichModal === 'login' ? loginTitleMessage : signUpTitleMessage}
						</div>
						<div class="h-8"></div>
						<button
							class="
				w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
				rounded shadow-sm border
				shadow-cyan-300 text-zinc-900 bg-cyan-400 bg-opacity-50 hover:brightness-90 border-zinc-400
				dark:shadow-zinc-500 dark:text-zinc-900 dark:bg-zinc-200 dark:hover:bg-zinc-50 dark:border-zinc-400 dark:hover:border-zinc-300"
							on:click={loginWithGoogle}
						>
							<i class="mr-5 fa-brands fa-google"></i>
							Continue with Google
						</button>
						<div class="h-4"></div>
						<div class="text-sm text-zinc-400">or</div>
						<div class="h-4"></div>
						{#if whichModal === 'login'}
							<input
								type="email"
								class="w-72 h-12 px-2 bg-zinc-50 border border-primary-200 dark:bg-navy-900 dark:border-zinc-700 text-sm rounded"
								placeholder="Email"
								autocomplete="username"
								bind:value={email}
							/>
							<div class="h-3"></div>
							<input
								type="password"
								class="w-72 h-12 px-2 bg-zinc-50 border border-primary-200 dark:bg-navy-900 dark:border-zinc-700 text-sm rounded"
								placeholder="Password"
								autocomplete="current-password"
								bind:value={password}
							/>
							<div class="h-3"></div>
							<button
								class="
					w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
					rounded shadow-sm
					bg-primary-100 hover:brightness-90
					dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:border dark:border-zinc-700 dark:hover:border-zinc-600
				"
								on:click={loginWithEmailPassword}
							>
								{whichModal === 'login' ? 'Log in' : 'Create account'}
							</button>
						{:else}
							<form on:submit|preventDefault={signUpWithEmailAndPassword} class="flex flex-col">
								<input
									type="email"
									class="w-72 h-12 px-2 bg-zinc-50 border border-primary-200 dark:bg-navy-900 dark:border-zinc-700 text-sm rounded"
									placeholder="Email"
									autocomplete="username"
									bind:value={email}
									required
								/>
								<div class="h-3"></div>
								<input
									type="password"
									class="w-72 h-12 px-2 bg-zinc-50 border border-primary-200 dark:bg-navy-900 dark:border-zinc-700 text-sm rounded"
									placeholder="Password"
									autocomplete="new-password"
									bind:value={password}
									required
								/>
								<div class="h-3"></div>
								<button
									type="submit"
									class="
						w-72 h-12 px-3 flex flex-row justify-center items-center text-sm font-semibold
						rounded shadow-sm
						bg-primary-100 hover:brightness-90
						dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:border dark:border-zinc-700 dark:hover:border-zinc-600
					"
								>
									Create account
								</button>
							</form>
						{/if}
						<div class="h-6"></div>
						{#if errorMessage !== ''}
							<div class="text-sm text-center text-rose-400 dark:text-rose-300">{errorMessage}</div>
							<div class="h-4"></div>
						{/if}
						{#if whichModal === 'login'}
							<div class="h-4 text-sm">
								<a
									class="text-cyan-600 hover:text-cyan-500 dark:text-cyan-400 dark:hover:text-cyan-300"
									href="/reset-password">Reset password</a
								>
							</div>
							<div class="h-4"></div>
						{:else}
							<div class="h-4"></div>
							<div class="h-4"></div>
						{/if}
						{#if whichModal === 'login'}
							<div class="text-sm">
								No account?
								<button
									class="text-cyan-600 hover:text-cyan-500 dark:text-cyan-400 dark:hover:text-cyan-300"
									on:pointerup={() => (whichModal = 'sign-up')}
								>
									Create one
								</button>
							</div>
						{:else}
							<div class="text-sm">
								Already have an account?
								<button
									class="text-cyan-600 hover:text-cyan-500 dark:text-cyan-400 dark:hover:text-cyan-300"
									on:pointerup={() => (whichModal = 'login')}
								>
									Log in
								</button>
							</div>
						{/if}
					</div>
				{/if}
			</div>
		</div>
	</Transition>
</div>
