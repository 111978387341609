<script lang="ts">
	import type { User } from 'firebase/auth';
	import posthog from 'posthog-js';
	import LoginOrSignUpModal from './LoginOrSignUpModal.svelte';

	export let user: User | null | undefined;
	export let href: string;
	export let eventText: string;
	export let loginOrSignUpModal: LoginOrSignUpModal;
	export let loginOrSignupWhichModal: 'login' | 'sign-up' = 'sign-up';

	function handleClick() {
		if (user === undefined) return;
		posthog.capture('cta_clicked', { text: eventText });
		loginOrSignupWhichModal = 'sign-up';
		loginOrSignUpModal.openDialog();
	}
</script>

{#if user}
	<a
		class="w-[12.5rem] h-14 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold
             text-zinc-100 shadow-md bg-zinc-900 border-zinc-700 hover:brightness-150"
		{href}
	>
		<slot />
	</a>
{:else}
	<button
		class="w-[12.5rem] h-14 px-3 flex flex-row justify-center items-center border rounded text-md font-semibold
             text-zinc-100 shadow-md bg-zinc-900 border-zinc-700 hover:brightness-150"
		on:click={handleClick}
	>
		<slot />
	</button>
{/if}
